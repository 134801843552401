export function propertyGroupForms (schema, is_staff) {
  const schemas = {
    'group-new': {
      title: 'Nieuwe groep',
      fields: [
        { value: 'name', label: 'Naam', placeholder: 'Naam', type: 'text', required: true },
        {
          value: 'description',
          label: 'Beschrijving',
          placeholder: 'Beschrijving',
          type: 'textarea',
          required: false
        },
        {
          value: 'is_public',
          label: 'Publiek',
          placeholder: '',
          type: 'checkbox',
          visibility_slave: ['confidants', 'offices'],
          reverse_visibility: true,
          visible: is_staff

        },
        {
          value: 'owner',
          label: 'Eigenaar',
          placeholder: 'Medewerker',
          type: 'collaborator',
          multiple: false,
          visible: is_staff
        },
        {
          value: 'confidants',
          label: 'Delen met',
          placeholder: 'Medewerker',
          type: 'collaborator',
          visibility_master: 'is_public',
          multiple: true
        },
        {
          value: 'offices',
          label: 'Delen met',
          placeholder: 'Kantoor',
          type: 'office',
          visibility_master: 'is_public',
          multiple: true
        },
        {
          value: 'properties',
          label: 'Panden',
          placeholder: 'Panden',
          type: 'property',
          multiple: true
        }
      ]
    },
    'group-edit': {
      title: 'Groep bewerken',
      fields: [
        { value: 'name', label: 'Naam', placeholder: 'Naam', type: 'text', required: false },
        {
          value: 'description',
          label: 'Beschrijving',
          placeholder: 'Beschrijving',
          type: 'textarea',
          required: false
        },
        {
          value: 'is_public',
          label: 'Publiek',
          placeholder: '',
          type: 'checkbox',
          visibility_slave: ['confidants', 'offices'],
          reverse_visibility: true

        },
        {
          value: 'confidants',
          label: 'Delen met',
          placeholder: 'Medewerker',
          type: 'collaborator',
          visibility_master: 'is_public',
          multiple: true
        },
        {
          value: 'offices',
          label: 'Delen met',
          placeholder: 'Kantoor',
          type: 'office',
          visibility_master: 'is_public',
          multiple: true
        }
      ]
    }
  }

  return schemas[schema]
}
