var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":"Pandgroepen"}}),_c('div',{staticClass:"tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto"},[_c('EditModal',{ref:"editModal",attrs:{"config":_vm.activeEditConfig,"object":_vm.activeObject},on:{"save":_vm.handleGroup}}),_c('FormulateForm',{staticClass:"tw-mb-8 tw-p-6 tw-bg-white tw-rounded tw-shadow-lg",attrs:{"name":"searchGroups"},on:{"submit":_vm.searchGroups},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-grid md:tw-grid-cols-3 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"text","name":"name","label":"Naam","placeholder":"Naam van de groep","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"office","name":"having_office","label":"Kantoren","placeholder":"Selecteer een kantoor","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","name":"having_collaborator","label":"Medewerker","placeholder":"Selecteer een medewerker","outer-class":"tw-m-0"}})],1),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-search'
          ]}),_vm._v(" Zoeken ")])]}}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('DataTable',_vm._b({attrs:{"loading":_vm.loading,"headers":_vm.headers},on:{"changedOrder":_vm.fetchGroups},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"tw-mb-2 tw-flex tw-flex-row tw-items-center tw-justify-between"},[_c('button',{staticClass:"action tw-bg-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.showEditModal('group-new', {})}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Nieuw ")]),(_vm.isStaff)?_c('FormulateInput',{attrs:{"type":"toggle","name":"admin","label":"Beheerder","outer-class":"tw-m-0"},model:{value:(_vm.adminView),callback:function ($$v) {_vm.adminView=$$v},expression:"adminView"}}):_vm._e()],1)]},proxy:true},{key:"item-name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'PropertyList', query: { group: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item-offices",fn:function(ref){
          var item = ref.item;
return [(item.offices.length)?_c('div',_vm._l((item.offices),function(office,index){return _c('a',{key:index,attrs:{"href":'/organization/office/' + office.id}},[_vm._v(" "+_vm._s(office.reference)+" ")])}),0):_c('span',[_vm._v("-")])]}},{key:"item-is_public",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"tw-text-base"},[(item.is_public)?_c('i',{staticClass:"far fa-check tw-text-primary"}):_c('i',{staticClass:"far fa-times tw-text-error"})])]}},{key:"item-actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"tw-flex"},[(item.owner === _vm.collaborator.id || _vm.isStaff)?[_c('button',{staticClass:"action tw-bg-primary",on:{"click":function($event){return _vm.showEditModal('group-edit', item)}}},[_c('i',{staticClass:"far fa-pencil"})]),_c('button',{staticClass:"action tw-bg-error",on:{"click":function($event){return _vm.handleGroupDelete(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})])]:_vm._e()],2)]}}])},'DataTable',_vm.groupData,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }